import React, { useEffect } from "react";
import "./App.css";

import {
  create,
  get,
  supported,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";

function assertIsFormFieldElement(
  element: Element
): asserts element is HTMLInputElement | HTMLSelectElement | HTMLButtonElement {
  if (!("value" in element)) {
    throw new Error(`Element is not a form field element`);
  }
}

export default function App() {
  useEffect(() => {
    document.title = "FDKevin WebAuthn";
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const firstField = event.currentTarget[0];
    assertIsFormFieldElement(firstField);

    const data = await fetch(
      "https://auth.fdkevin.cloud/api/webauthn/registration/initialize",
      { method: "POST", headers: { "x-user-name": firstField.value } }
    );
    const json = await data.json();
    const options = parseCreationOptionsFromJSON(json);
    const regCred = await create(options);
    fetch("https://auth.fdkevin.cloud/api/webauthn/registration/finalize", {
      method: "POST",
      headers: { "x-user-id": json.publicKey.user.id },
      body: JSON.stringify(regCred),
    })
      .then((resp) => resp.json())
      .then((verify) => {
        alert("Registration success!");
        console.log(verify);
      });
  };

  const handleLogin = (mediation: CredentialMediationRequirement) => {
    fetch("https://auth.fdkevin.cloud/api/webauthn/login/initialize", {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then(async (json) => {
        const options = parseRequestOptionsFromJSON(json);
        console.log(options);
        options.mediation = mediation;
        const authCred = await get(options);
        console.log(authCred);
        fetch("https://auth.fdkevin.cloud/api/webauthn/login/finalize", {
          method: "POST",
          body: JSON.stringify(authCred),
        })
          .then((resp) => resp.json())
          .then((data) => {
            alert("Login success!");
            console.log(data);
          });
      });
  };

  if (supported()) {
    handleLogin("conditional" as CredentialMediationRequirement);
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="FDKevin"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="usernameInput"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Username
            </label>

            <div className="mt-2">
              <input
                name="username"
                type="text"
                autoComplete="webauthn"
                required
                autoCorrect="off"
                placeholder="Username"
                // onChange={(e) => setUsername(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Register with Passkeys (WebAuthn)
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => handleLogin("required")}
              className="flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border border-gray-400"
            >
              Signin with Passkeys (WebAuthn)
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
